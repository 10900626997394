import React from 'react'
import Ser from 'components/Services7/services7.jsx'
const page = () => {
    return (
        <div>
            <Ser />
        </div>
    )
}

export default page